import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Divider, Container, Typography, Stack, TextField, Button, CircularProgress } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { formatarParaSelect } from '../utils/utils';
import SaveIcon from '@mui/icons-material/Save';
import { API_KEY } from '../config'

const tipos = [
    ['R', 'REAGENDAMENTO'],
    ['E', 'EXCLUSÃO DA BASE']
];

// Criacao do schema para validar a adicao de local
const schema = yup.object().shape({
    mensagem: yup.string().min(5).required(),
    tipo: yup.object().shape({
        key: yup.string().min(1).required(),
        label: yup.string().min(1).required(),
        value: yup.string().min(1).required(),
    }).required(),
});


export default function ReagendamentoDeEntrevista() {
  const [ aguardar, setAguardar ] = useState(false);
  // Para referenciar o captcha invisivel
  const recaptchaRef = useRef();
  //
    
    // Estilização do componente select
    const selectStyles = {
        multiValueRemove: (styles)=> ({
            ...styles,
            color: 'black',
        }),
        menu: (styles)=>({
            ...styles,
            zIndex: 1000,
        }),
        option: (styles)=>({
            ...styles,
            zIndex: 1000,
            color: 'black',
        }),    
                
    }
  const { control, handleSubmit, formState: { errors } } = useForm({
      resolver: yupResolver(schema)
  });
  
  const fn = async (val)=>{
      setAguardar(true);
      const obj = {};
      const { tipo, mensagem } = val;
      // Reseta o captcha e recria-o
      await recaptchaRef.current.reset();
      obj['captcha'] = await recaptchaRef.current.executeAsync();
      obj['tipo'] = tipo.value;
      obj['mensagem'] = mensagem;
      // Pega o codigo da pagina (param) ele e necessario para submeter o reagendamento
      obj['chave'] = new URLSearchParams(window.location.search).get('codigo');
      //
      const formData = new FormData();
      formData.append('dados', JSON.stringify(obj));

      try {
          const resp = await axios.post('/reagendamento_de_entrevista', formData);
          if(resp.status !== 200){
              toast.dark('Erro interno do servidor', {
                  type: 'error'
              })
              return false;
          }
          // tudo certo vamos prosseguindo para verificar se teve alguma mensagem de erro
          if(resp.data.erro){
              toast.dark(resp.data.erro, {
                  type: 'error'
              });
              return false;
          }
          // Exibir mensagem de sucesso ao usuario e redirecionar pagina
          toast.dark(resp.data.sucess, {
              type: 'success'
          });

          setTimeout(() => {
            window.location.href = '/';
          }, 5000);          
      } catch (error) {
          console.log(error);
          toast.dark('ERRO AO TENTAR SUBMETER FORMULARIO', {
              type: 'error'
          });
          return false;          
      } finally {
          setAguardar(false);
      }
  }

  return (
      <Container maxWidth='lg'>
          <ToastContainer position='bottom-center' />
          <Typography variant='h5' align='center'>
              Reagendamento ou Desistência
          </Typography>
          <Typography variant='body2' align='center'>
              Descreva no formulário abaixo o tipo de motivo que fará com que você não possa comparecer na entrevista e com suas palavras descreva o que te fez tomar esta decisão.
          </Typography>
          
          <Divider sx={{my: 1}} />
          <Container disableGutters maxWidth='md'>
            <Controller 
                name='tipo'
                control={control}
                defaultValue=''
                rules={{required: true}}
                render={({ field })=>(
                    <Stack>
                        <Typography variant='subtitle2' fontWeight='bold'>
                            Tipo
                        </Typography>
                        <Select {...field} styles={selectStyles}
                            options={formatarParaSelect(tipos)}
                            isDisabled={aguardar}
                        />
                        {!!errors.tipo && (
                            <Typography variant='caption' color='error'>
                                * Escolha um dos motivos listados
                            </Typography>
                        )}
                    </Stack>
                )}
                />
            <Controller 
                name='mensagem'
                control={control}
                defaultValue=''
                rules={{required: true}}
                render={({ field })=>(
                    <TextField {...field} sx={{mt: 2, mb: 1}}
                        label='Descrição' disabled={aguardar}
                        fullWidth type='textarea' minRows={2}
                        placeholder='Descreva o motivo que levou você a escolher esta opção'
                        multiline InputLabelProps={{shrink: true}}
                        helperText={!!errors.mensagem && '* Minimo de 5 caracteres'}
                        error={!!errors.mensagem}
                    />
                )}
                />
            <Container disableGutters maxWidth='xs' >
                <Button fullWidth onClick={handleSubmit(fn)} disabled={aguardar} variant='contained' startIcon={aguardar ? <CircularProgress size={20} /> : <SaveIcon />}>
                    SALVAR
                </Button>
            </Container>
        </Container>
        <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={API_KEY} 
        
        />
      </Container>
  )
}
