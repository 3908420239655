import { useState } from 'react';
/**
 * Este hook lida com a logica para controlar o avanço das respostas nos campos
 * 
 */
export default function useQuestionario(ultimosDadosSalvos, contadorAtual){
    const [contador, setContador] = useState(contadorAtual || 0);
    const [resposta, setResposta] = useState(ultimosDadosSalvos || [null] );

    const avancar = (valor)=>{
        // Criar incremento
        const incremento = contador + 1;
        // Pegar o valor enviado, colocar na posicao atual 
        const copia = [...resposta];
        copia[contador] = valor;
        // Se o proximo valor ja existir nao apendar
        if(incremento > copia.length - 1 ) copia.push(null);
        // Para incrementar o valor do contador em 1
        setContador(incremento);
        // Atualizar as respostas
        setResposta(copia); 
        // Atualiza também o armazenamento local
        localStorage?.setItem('dadosForm', JSON.stringify(copia));
        localStorage?.setItem('contadorAtual', incremento);
        
    }
    const recuar = ()=>{
        if(contador > 0) {
            const valor = contador - 1;
            setContador(valor);
            localStorage?.setItem('contadorAtual', valor);

        }
    }

    return {
        contador, resposta, avancar, recuar
    }
}