import React from 'react';
//import { Typography, InputLabel, NativeSelect } from '@material-ui/core';
import { Typography, InputLabel, NativeSelect } from '@mui/material';


const SelectPai = React.forwardRef((props, ref)=>{

    const { label, options } = props;

    return (
        <>
            <InputLabel htmlFor='_select'>{label}</InputLabel>
            <NativeSelect {...props} ref={ref}>
            {options && options.map((ele,idx)=>(
                <option value={ele[0]} key={idx}>{ele[1]}</option>
            ))}
            </NativeSelect>
            {props.error && <Typography color='error' variant='caption'>{props.helperText}</Typography>}
        </>
    )
});

export default SelectPai