import React, { memo } from 'react';
import Logo from '../images/logo.png';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import BrightnessMediumIcon from '@mui/icons-material/BrightnessMedium';

import { Stack, Paper, IconButton } from '@mui/material';
import LogoBranca from '../images/logo_branca.svg';

const Header = ({ tema, setTema })=>{

    const fn = valor=> setTema(valor);
    
    return (
        <Paper sx={{px: 1, pt: 1 }} elevation={3} >
            <Stack direction='row' justifyContent='space-between'>
                <img initial='initial' animate='animate' exit='exit'
                    src={tema === 'dark' ? LogoBranca : Logo} 
                    alt="Logo da Empresa" height='36px' 
                />    
                <IconButton onClick={()=> fn(tema && tema === 'dark' ? 'light' : 'dark')}>
                    {tema === 'dark' ? <BrightnessHighIcon  /> : <BrightnessMediumIcon />}
                </IconButton>   
            </Stack>
        </Paper>
    )
}

export default memo(Header);