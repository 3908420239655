

export const scale = {
    initial: {
        scale: 0.01,
    },
    animate: {
        scale: 1,
        transition: {
            duration: .3,
            delay: .5,
        }
    },
    exit: {
        scale: 0.01,
        transition: {
            duration: .1,
        }

    }
}

export const leftToPosition = {
    initial: {
        x: '-100vw',
    },
    animate: {
        x: 0,
        transition: {
            duration: .3,
            delay: .2,
        }
    },
    exit: {
        x: '200vw',
        transition: {
            duration: .1,
        }

    }
}