import React from 'react'
//import { LinearProgress, Box, Typography } from '@material-ui/core'
import { LinearProgress, Box, Typography } from '@mui/material'

export default function LinearProgressWithLabel({value}) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={value} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body1">{`${Math.round(
            value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }