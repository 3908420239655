import React, { useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material';
import Lottie from 'react-lottie';
import dataLottie from '../lotties/conclusao.json';

const STRINGS = {
    titulo: 'PARABÉNS !!!',
    corpo1: 'Seu curriculo foi incluso com sucesso em nossa base de recrutamento.',
    corpo2: 'Nosso time de recrutamento irá analisar o seu curriculo, portanto fique ligado no seu telefone e/ou email.',
    corpo3: 'BOA SORTE !!!'
}

// const BoxWrapper = withStyles({
//     root: {
//         height: 'calc(100vh - 78px)',
//     }
// })(Box);

const CadastroConclusao = ()=>{
    useEffect(()=>{
        window.setTimeout(()=> window.location.href = '/', 10000);
        // Limpando os dados salvos no armazenamento local
        localStorage?.clear();
    }, []);
    const {breakpoints : { values } } = useTheme();
    const isMobile = values['sm'] > window.innerWidth;

    const defaultOptions = {
        loop: true,
        //autoplay: ativar,
        delay: 2000,
        animationData: dataLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Box display='flex' flexDirection='column' justifyContent='space-around'>
            <Box flex={2} >
                <Typography variant='h3' align='center'>{STRINGS.titulo}</Typography>
                <Typography align='center' variant='body1'>{STRINGS.corpo1}</Typography>
                <Typography align='center' variant='body1'>{STRINGS.corpo2}</Typography>
            </Box>
            <Box flex={2}>
                <Lottie 
                        options={defaultOptions}
                        width={isMobile ? 200 : 400}
                        height={isMobile ? 200 : 400}
                    />
            </Box>
            <Box flex={1}>
                <Typography align='center' variant='h4'>{STRINGS.corpo3}</Typography>
                
            </Box>

        </Box>
    )
}

export default CadastroConclusao