import React from 'react';
import { motion } from 'framer-motion'
import { Button, Grid, Container, Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

import {  useHistory } from 'react-router-dom'
import { scale } from '../effects';
import Lottie from 'react-lottie';
import dataLottie from '../lotties/inicio.json';

const STRINGS = {
    title1: 'Venha trabalhar conosco !',
    subtitle1: 'Estamos a procura de colaboradores que estejam dispostos a contribuir para o crescimento da empresa.',
    subtitle2: 'Clique no botão abaixo para se inscrever.',
}

const MotionButton = motion(Button);

const TrabalheConosco = ()=>{
    const history = useHistory();
    const {breakpoints : { values } } = useTheme();
    const isMobile = values['sm'] > window.innerWidth;
    
    const fn = ()=>{
        history.push('/cadastro');
    }
    const defaultOptions = {
        loop: true,
        //autoplay: ativar,
        delay: 2000,
        animationData: dataLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Container>
            
            <Grid sx={{p: 1}} container spacing={4} >
                <Grid flex={1} item xs={12}>
                    <Typography variant="h4">{STRINGS.title1}</Typography>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">{STRINGS.subtitle1}</Typography>
                    <Typography variant="body1">{STRINGS.subtitle2}</Typography>
                </Grid>
                
                
                <Grid  item xs={12} md={3}>
                    <MotionButton onClick={fn} fullWidth endIcon={<DoubleArrowIcon />} variant='contained' color='primary' variants={scale} initial="initial" animate="animate" exit="exit">INSCREVA-SE!</MotionButton>
                </Grid>
                <Grid item xs={12} md={3}>
                
                <Lottie 
                    options={defaultOptions}
                    width={isMobile ? 200 : 400}
                    height={isMobile ? 200 : 400}
                />
                
                </Grid>
                
            </Grid>
            
        </Container>
    )
}

export default TrabalheConosco