import React,{ useState } from 'react';
import { Paper, createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { TrabalheConosco, Cadastro, CadastroConclusao } from './routers';
import { Header } from './components';
import 'react-toastify/dist/ReactToastify.css';
import { green, red } from '@mui/material/colors'
import ReagendamentoDeEntrevista from './routers/reagendamento-de-entrevista';


const routers = [ 
  ['/', TrabalheConosco],
  ['/cadastro', Cadastro],
  ['/cadastro_conclusao', CadastroConclusao],
  ['/reagendamento_de_entrevista', ReagendamentoDeEntrevista],
]


function App() {
  // Atualizador de tema
  const [tema, setTema ] = useState('light');
  
  // // Criando e aplicando tema
  const theme = createTheme({ 
    palette: {
      mode: tema,
      primary:  {
        main: tema === 'dark' ? red['A700'] : '#b71c1c',
      },
      secondary: {
        main: tema === 'dark' ? green['A700'] : green[900],
      }
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <Paper sx={{minHeight: '100vh'}}>
      <Router>
        <Header 
          tema={tema} 
          setTema={setTema} 
        />
        {routers.map((ele,idx)=>(
          <Route key={idx} path={ele[0]} component={ele[1]} exact />
        ))}      
      </Router>
    </Paper>
    </ThemeProvider>
  );
}

export default App;
