import React from 'react';
import ReactDOM from 'react-dom';
//import 'normalize.css';
import './App.css';
import App from './App';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import rootReducer from './redux/store/index';
import { Provider } from 'react-redux';

const store = createStore(rootReducer, applyMiddleware(reduxThunk));


ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
);

